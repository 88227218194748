.heading-section {
  background-color: white;
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);
  padding: 1rem;
  height: fit-content;
  z-index: 1000;
  position: relative;
}

.ant-affix {
  z-index: 1000;
}

.heading-section__title {
  margin: 0 !important;
}

.heading-section__breadcrumb {
  height: 2rem;
  position: relative;
  transform: translateY(-0.5rem);
}

.heading-section__body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  row-gap: 0.5rem;
}

.heading-wrapper {
  align-self: center;
  display: flex;
  position: relative;
  padding-right: 2rem;
}

.back-button {
  align-self: center;
  cursor: pointer;
  margin-right: 16px;
}

.bottom-component-wrapper {
  flex-grow: 1;
}
