main {
  padding: 24px 30px 246px 24px;
}

.IntersectionPage .heading-section-container {
  grid-column: 1/2;
  grid-row: 1;
  box-shadow: var(--card-shadow);
  border-radius: var(--card-radius);
}

.IntersectionPage .map-list {
  grid-column: 1/2;
  grid-row: 2;
  position: relative;
  box-shadow: var(--card-shadow);
  border-radius: var(--card-radius);
  margin-top: 20px;
  background-color: var(--fg);
}

.IntersectionPage .map-list .ant-collapse {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 999;
}

.IntersectionPage .map-list__body {
  overflow: hidden;
  z-index: 0;
  min-height: 300px;
}
.IntersectionPage .collapse-button {
  cursor: pointer;
}

.IntersectionPage .modal-collapse {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
}

.IntersectionPage .tab__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  height: 50px;
}
.IntersectionPage .tab__footer {
  padding: 0 20px;
}

.IntersectionPage .tab__header__search {
  right: 140px;
  position: absolute;
}
.IntersectionPage .updated-time {
  width: 300px;
  height: 30px;
}

.IntersectionPage .skeleton-map {
  padding: 20px;
  width: 100%;
  height: 260px;
}
