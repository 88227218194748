.skeleton {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0;
  width: 100%;
  height: 100%;
}

.skeleton__item {
  height: inherit;
  display: contents;
}

.skeleton__item span {
  height: 100% !important;
}
