.common-chart {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.common-chart__tools {
  position: absolute;
  right: 1rem;
}
