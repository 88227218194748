.edit-device-modal .ant-modal-body {
  padding-bottom: 0px;
}

.edit-device-modal-form-item {
  margin-left: 3%;
  margin-bottom: 15px; 
}

.dot {
  display: 'inline-block';
  height: 8px;
  width: 8px;
  margin-right: 8px;
  border-radius: 50%;
}

.edit-device-modal .ant-modal-footer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-bottom: 5px;
}
