.custom-tooltip-margin {
  margin-top: 3px;
}
.custom-flex-container {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}

.custom-full-width {
  width: 100%;
}

.padding-form {
  padding: 20px 40px;
}
