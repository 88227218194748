.outputs-form {
  display: flex;
  flex-direction: column;
}

.outputs-form__channel-options {
  display: flex;
  gap: 2rem;
  overflow-x: auto;
}

.outputs-form__channel-options p {
  white-space: nowrap;
}

.outputs-form__format-option {
  max-width: 22rem;
}

.outputs-form__channels {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  padding-right: 2rem;
}

.outputs-form__channel {
  overflow-x: scroll;
}

.outputs-form__warning-bar {
  margin-bottom: 1rem;
}

.outputs-form__edit-warning {
  padding-left: 1rem;
  color: red;
}

.outputs-form__output-options {
  display: grid;
  grid-template-columns: 4.5rem 1fr;
  min-width: 12rem;
  gap: 0.5rem;
}

.outputs-form__output-options--single {
  grid-template-columns: 1fr;
}

.outputs-form__channel-title {
  white-space: nowrap;
}

.outputs-form__reset {
  max-width: 12rem;
  margin-left: auto;
  margin-right: auto;
}