.table-skeleton {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 216px;
}

.ant-skeleton-element .ant-skeleton-button {
  display: block;
  width: auto;
}

ul.ant-pagination.ant-table-pagination {
  background-color: #ffffff;
  margin: 0;
  padding: 17px 24px 2px;
}

.ant-table-cell.ant-table-selection-col.ant-table-before {
  padding: 0;
}

.ant-table-cell.ant-table-selection-col.ant-table-before .ant-radio-wrapper {
  margin-left: 8px;
}

.ant-table-before::before {
  display: none;
}
