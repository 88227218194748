.regions-layout {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 80px 10%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: var(--bg);
}

.regions-layout__steps {
  background-color: var(--fg);
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  padding-right: 1rem;
  width: fit-content;
  min-width: 50%;
}

.regions-layout__step {
  width: fit-content;
}

.regions-layout__body {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.regions-layout__body > * {
  background-color: var(--fg);
  padding: 1rem;
}
