.map-list.ant-collapse.map-settings {
  width: 33%;
  min-width: 150px;
  border: none;
  box-shadow: var(--card-shadow);
}

.map-list .ant-collapse > .ant-collapse-item {
  border-bottom: none !important;
}

.map-list .ant-collapse.map-settings .ant-collapse-header {
  background-color: #ffffff;
}

.map-list .ant-collapse-content {
  height: 142px;
}

.map-list .map-settings .ant-collapse-content-box {
  padding: 0;
}

.map-list .map-settings__settings .ant-select {
  width: 90%;
  margin: 10px 5%;
}

.map-list .map-settings__legend {
  background-color: #eae9f0;
}
.map-list ul.legend__left {
  list-style-type: none;
  padding: 10px;
  width: 200px;
}
.map-list .legend__left .col-1 {
  font-size: 1.15em;
  font-weight: 700;
  margin-bottom: 6px;
}

.map-list .legend__left .col-2 {
  width: 200px;
  max-width: 33%;
  margin-bottom: 5px;
}

.map-list .legend__left .legend {
  background-color: var(--normal);
  color: white;
  border: none;
  display: inline;
  padding: 1px 5px;
  font-size: small;
}
.map-list .legend__left .legend-2 {
  background-color: var(--warning);
}

.map-list .legend__left .legend-3 {
  background-color: var(--error);
}

.map-list .legend__left .col-3 {
  font-size: small;
  font-weight: 500;
  max-width: fit-content;
  margin-bottom: 0;
}
