.activity-tabs .ant-tabs-nav-wrap {
  background-color: #e6f7ff;
  padding-top: -10px;
}

.activity-tabs .ant-tabs-nav-wrap {
  padding-left: 20px;
}
.activity-tabs .ant-tabs-ink-bar {
  background: #595959 !important;
  color: #595959 !important;
}
.activity-tabs :hover {
  color: #595959 !important;
}
.activity-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #595959;
  text-shadow: 0 0 0.25px currentcolor;
}
