.approaches-modal {
  width: 100px;
  min-width: 350px;
  margin: 50px 0px 0px 40px;
  background-color: white;
  box-shadow: var(--card-shadow);
  border-radius: var(--card-radius);
}

.approaches-modal-top {
  padding: 10px 15px 15px 15px;
  overflow-wrap: break-word;
}

.approaches-modal-title {
  font-size: 24px;
  font-weight: 500;
  font-family: Roboto;
  color: #434343;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.approaches-modal-lat-lng {
  font-size: 12px;
  margin-right: 22%;
}

.approaches-modal-bottom {
  padding: 5px 15px 10px 15px;
  background-color: #f5f5f5;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  max-height: 300px;
  overflow-y: auto;
}

.approaches-modal-bottom .ant-descriptions-item-content {
  padding: 0px !important;
  padding-left: 11px !important;
}

.approaches-modal-bottom .ant-descriptions-item-label {
  padding: 0px !important;
  padding-left: 11px !important;
  padding-right: 11px !important;
  width: 65%;
}

.approaches-modal-tools {
  margin-left: 2%;
  margin-right: 2%;
}

.approaches-modal-tools-text {
  margin: 10px !important;
  margin-left: 12.5px !important;
  margin-right: 12.5px !important;
  display: inline-block;
}

.approaches-modal-selection h3,
.approaches-modal-selection p {
  color: lightgrey !important;
}

.approaches-modal-title {
  margin: 0px 24px 0px 0px;
  display: inline-block;
}

.approaches-modal-collapse {
  display: inline-block;
  margin: 0px 0px 0px 12px;
  font-size: 24px;
  position: absolute;
  right: 10px;
  left: auto;
}

.collapse-button {
  cursor: pointer;
}

.backRight {
  margin-right: 7px;
}

.hover-width-bold {
  font-weight: normal; /* Initial font-weight */
  cursor: pointer;
}

.hover-width-bold:hover {
  font-weight: bold;
  cursor: pointer;
}

.editable-input {
  width: 48px;
  height: 20px;
  text-align: center;
  align-content: 'center';
  margin-left: 14px;
}
