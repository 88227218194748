.thresholds-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #363636 !important;
}

.thresholds-tabs .ant-tabs-ink-bar {
  position: absolute;
  background: dimgray;
  pointer-events: none;
}

.thresholds-form-item .ant-form-item-label {
  margin-bottom: 10px;
}

.thresholds-form-item input {
  width: 25%;
}

.thresholds-button-edit {
  background-color: #ffffff !important;
  color: #0290d4 !important;
  margin: 0px !important;
  width: 85%;
}

.thresholds-buttons {
  margin-top: 10px;
}

.thresholds-button-apply {
  margin: 0px !important;
}

.priority-switch {
  display: flex;
  flex-direction: row !important;
  position: relative;
}

.priority-switch label {
  margin-top: 6px !important;
}

.priority-switch p {
  margin: 0px;
  font-weight: 500;
}

.thresholds-form-channel-title {
  width: 10.5rem;
  font-weight: bold;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.thresholds-form .ant-col {
  padding: 0 1.5rem 0 0;
}
