.route-details {
  display: grid;
  grid-gap: 1rem;
}

.route-details__sign {
  font-size: 3rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-basis: auto !important;
}

.map-container {
  z-index: 50;
  position: relative;
}

.map-card {
  width: 400px;
  padding: 5px;
}

.map-card .ant-card-body {
  padding: 0px;
}

.map-card h1 {
  font-size: 25px;
  margin: 0px;
}

.map-card p {
  margin: 0px;
}
