:root {
  /* Colors */
  --fg: white;
  --bg: #f5f5fa;
  --border: #999999;
  --icon-color: #0290d4;
  --graph-highlight: #3911bc;

  --text: black;
  --text-contrast: white;
  --text-secondary: #4f4f64;

  --error: #ff4d4f;
  --warning: #faad14;
  --normal: #52c41a;
  --total: #262626;

  /* Sizing */
  --map-height: 659px;

  /* Decoration */
  --card-shadow: 0px 2px 7px 0px #0000001a;
  --card-radius: 8px;
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  /* Animation */
  --acc-back: cubic-bezier(0.39, 0.575, 0.565, 1);

  /* Media Queries */
  --screen-desktop: 1281px;
  --screen-laptop: 1070px;
  --screen-tablet: 768px;
  --screen-mobile: 481px;
  --screen-phone: 320px;
}

body {
  margin: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  position: relative;
}

#root {
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-pagination-item-link {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.react-bootstrap-table table {
  table-layout: auto !important;
}

.Login {
  padding: 100px 0;
}

.Login form {
  margin: 0 auto;
  max-width: 320px;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  height: 200px;
  width: 200px;
}
.Login h1 {
  text-align: center;
}

.Edit {
  padding: 2;
  border-radius: '10px';
}
.Edit h5 {
  font-size: medium;
}

.IntersectionsPage {
  padding: 80px 0;
  margin: 0 auto;
  max-width: 1000px;
}

.SearchTable {
  margin-bottom: 1.5%;
}

/* Map tooltips */

/* Select tooltip directly */
/* .gm-style img {} */

.tooltip-map {
  white-space: pre-line;
  position: absolute;
  left: 50%;
  bottom: 1.3rem;
  transform: translateX(-50%);
  color: #626161;
  font-size: 8px !important;
  line-height: 20px !important;
}

.tooltip-map--top {
  top: 2.1rem;
}

.tooltip-map--empty.tooltip-map--top {
  top: 2.3rem;
}

.create-agency {
  text-align: center;
  margin-bottom: 5%;
}

.tooltip-map--empty:not(.tooltip-map--top) {
  bottom: 2.2rem;
}

.tooltip-map::first-line {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
}

.tooltip-map--sd {
  white-space: pre-line;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  color: #626161;
  font-size: 8px !important;
  line-height: 8px !important;
  top: 23px;
}

.tooltip-map--sd::first-line {
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.content-padding {
  padding: 10px 28px;
}
