.side-menu__sub-menu {
  color: #fff;
  font-weight: bold;
  background-color: black;
  overflow: hidden;
}

.side-menu__icon {
  font-size: 20px !important;
}

.side-menu__icon path {
  fill: rgba(255, 255, 255, 0.904);
}

.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(255, 255, 255, 0.904) !important;
  background: rgb(39, 39, 39) !important;
}
