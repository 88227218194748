.ant-layout-sider {
  height: 100vh;
  left: 0;
  position: fixed !important;
  top: 50px;
  z-index: 1000 !important;
  background-color: black;
}

.ant-layout.layout {
  min-height: 100vh;
}

.ant-layout {
  background-color: #f5f5fa;
  transition: all 0.2s;
  padding-top: 3rem;
  min-height: 100vh;
}

.ant-layout.content {
  padding-top: 54px;
}

.ant-notification {
  z-index: 9999;
}

div.ant-notification {
  z-index: 9999;
}

span.ant-select-selection-item svg {
  vertical-align: initial;
}