.channel-legend {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 355px;
  height: 11.5%;
  display: flex;
  position: absolute;
  bottom: 3.5%;
  background-color: #ffffff;
  margin-left: 3%;
  padding: 10px;
  padding-left: 20px;
  box-shadow: var(--card-shadow);
  border-radius: var(--card-radius);
}

.channel-legend p {
  margin-top: auto;
  margin-bottom: 0;
  font-weight: bold;
}

.channel-legend-label {
  grid-column: 1 / 5;
  grid-row: 2;
  margin-left: auto;
  margin-right: auto;
}

.channel-legend-one {
  grid-column: 1;
  grid-row: 1;
}

.channel-legend-two {
  grid-column: 2;
  grid-row: 1;
}

.channel-legend-three {
  grid-column: 3;
  grid-row: 1;
}

.channel-legend-four {
  grid-column: 4;
  grid-row: 1;
}

.channel-color-icon {
  width: 23px;
  height: 23px;
  border-radius: 2px;
  margin-top: auto;
}

.channel-color-label {
  margin-left: 39%;
}
