.create-agency-modal__org-id-input {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.create-agency-modal__org-id {
    margin-bottom: 0.5rem;
    display: inline-flex;
    gap: 0.5rem;
}

.create-agency-modal__warning {
    margin-bottom: 1.5rem;
    color: red;
}