.intersections-list__controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.intersections-list__status {
  padding-left: 1.5rem;
}

.intersections-list__status::after {
  content: '';
  width: 0.5rem;
  height: 0.5rem;
  display: block;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.intersections-list__status.normal::after {
  background-color: var(--normal);
}

.intersections-list__status.error::after {
  background-color: var(--error);
}

.intersections-list__status.warning::after {
  background-color: var(--warning);
}