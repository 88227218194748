.mio-error-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
}

.mio-error-page > h1 {
  margin: 0px;
}
