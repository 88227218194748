.title {
    padding: 0 10px 0 0;
}
.all-intersections {
    padding:10px;
} 

.complete-modal {
    width: 300px;
    box-shadow: var(--card-shadow);
    border-radius: var(--card-radius);
    background-color: white;
    margin: 2%;
}