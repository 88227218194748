.general-form .ant-form-item-label {
  margin-bottom: 7px;
}

.general-form-header {
  font-size: 16.5px;
  font-weight: 600;
}

.general-form-col {
  padding: 0 1.5rem 0 0;
}

.form-item-style-1 {
  margin-right: 15.5rem;
}

.form-item-style-2 {
  margin-right: 14rem;
}

.form-item-style-3 {
  display: inline-block;
  margin-right: 1rem;
}

.form-item-style-4 {
  display: inline-block;
  width: 9rem;
  margin-right: 1rem;
}

.form-item-style-5 {
  margin-right: 7rem;
}
